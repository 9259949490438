.Waterfly {
  background-color: #00a9f4;
  display: flex;
  margin-top: 50px;
  padding: 10vmin 1rem;
  flex-direction: row;
  align-items: center;
}

.Waterfly-image {  
  flex: 1;
}

.Waterfly-ss {
  height: 100vmin;
  border-radius: 5px;
  align-self: flex-end;
}

.Waterfly-data {
  margin-left: 20px;
  margin-right: 20px;
  color: white;
  text-align: left;
  flex: 1;
}

.Waterfly-title {
  font-size: 8vmin;
}

.Waterfly-subtitle {
  font-size: 4vmin;
}

.Waterfly-description {
  font-size: 3vmin;
  margin-top: 2vmin;
  white-space: pre-wrap;
}

.Waterfly-link {
  font-size: 4vmin;
  margin-top: 2vmin;
}

.Waterfly-redirect {
  height: 1.5vmin;
  margin-left: 0.5vmin;
  margin-bottom: 1vmin;
}