.Electronics {
  background-color: #ffffff;
  display: flex;
  padding: 10vmin 1rem;
  flex-direction: column;
  font-size: 4vmin;
  color: #282c34;
  text-align: center;
}

.Electronics-title {
  font-size: 4vmin;
  text-align: center;
}

.Electronics-grid {
  /* flex: 1; */
  /* background-color: lawngreen; */
  margin-top: 8vmin;
}

.Electronics-row {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  /* background-color: lightcoral; */
  /* margin-top: 2vmin; */
  justify-content: center;
}

.Electronics-redirect {
  height: 1.5vmin;
  margin-left: 0.5vmin;
  margin-bottom: 1vmin;
}

.Electronics-footer {
  margin-top: 8vmin;
}