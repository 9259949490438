.Header {
  white-space: pre-wrap;
  background-color: white;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 4vmin;
  color: #282c34;
  margin-top: 10vmax;
  margin-bottom: 10vmax;
  margin-left: 10px;
  margin-right: 10px;
}

.Header-title {
  font-size: 8vmin;
  margin-bottom: 2vmin;
}

.Header-link {
  color: #00a9f4;
  text-decoration: none;
}