.Tutorial {
  background-color: #00a9f4;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5vmin;
  color: #ffffff;
  padding: 10vmin 1rem;
}

.Tutorial-title {
  font-size: 4vmin;
  margin-bottom: 4vmin;
}

.Tutorial-image {  
  flex: 1;
}

.Tutorial-ss {
  height: 50vmin;
  border-radius: 5px;
  align-self: flex-end;
  margin-top: 3vmin;
  margin-bottom: 3vmin;
}

.Tutorial-desc {
  margin-top: 3vmin;
  font-size: 3vmin;
}

.Header-link {
  color: #00a9f4;
  text-decoration: none;
}

.Tutorial-redirect {
  height: 1.5vmin;
  margin-left: 0.5vmin;
  margin-bottom: 1vmin;
}