.Item-Box {
  display: flex;
  background-color: khaki;
  height: 50vmin;
  width: 50vmin;
  margin: 1vmin;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Item-Box img {
  flex-shrink: 0;
  /* max-width: 100%; */
  max-height: 100%
}

.Item-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000AA;
  padding: 3vmin;
  color: #FFFFFF;
  font-size: 3vmin;
}

.Item-redirect {
  height: 1.5vmin;
  margin-left: 0.5vmin;
  margin-bottom: 1vmin;
}

.Item-Box a {
  color: #ffffff;
  text-decoration: none;
}